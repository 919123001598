<template>
  <v-container>
    <v-row justify="space-around" v-if="verein">
      <v-col cols="12">
        <v-row justify="end">
          <v-col cols="auto">
            <v-btn rounded large color="success" :disabled="!unsaved_changes" @click="save">
              <font-awesome-icon icon="fa-solid fa-floppy-disk" class="mr-2" size="xl" />
              Speichern
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="6">
        <v-text-field
          hide-details="auto"
          filled
          rounded
          label="Name"
          v-model="verein.name"
          @input="update"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="6">
        <v-text-field
          hide-details="auto"
          filled
          rounded
          label="Kurz"
          v-model="verein.kurz"
          @input="update"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-textarea
          hide-details="auto"
          filled
          rounded
          label="Beschreibung"
          v-model="verein.beschreibung"
          @input="update"
        ></v-textarea>
      </v-col>
      <v-col cols="12">
        <v-text-field
          filled
          rounded
          label="URL Vereinswebsite"
          v-model="verein.website"
          @input="update"
          hint="Format: https://example.com"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="auto" class="text-center">
        <b>Primäre Vereinsfarbe</b>
        <v-color-picker
          dot-size="25"
          hide-mode-switch
          mode="hexa"
          class="rounded-xl mx-auto"
          swatches-max-height="200"
          v-model="verein.farben.primary"
          @change="update"
        ></v-color-picker>
      </v-col>
      <v-col cols="12" md="auto" class="text-center">
        <b>Sekundäre Vereinsfarbe</b>
        <v-color-picker
          dot-size="25"
          hide-mode-switch
          mode="hexa"
          class="rounded-xl mx-auto"
          swatches-max-height="200"
          v-model="verein.farben.secondary"
          @change="update"
        ></v-color-picker>
      </v-col>
      <v-col cols="12" md="auto" class="text-center">
        <b>Textfarbe</b>
        <v-color-picker
          dot-size="25"
          hide-mode-switch
          mode="hexa"
          class="rounded-xl mx-auto"
          swatches-max-height="200"
          v-model="verein.farben.text"
          @change="update"
          @input="update"
        ></v-color-picker>
      </v-col>
      <v-col cols="12" md="4" align-self="center">
        <v-row justify="center">
          <v-col cols="6" align-self="center">
            <v-img :src="verein.logo" width="100%"></v-img>
          </v-col>
          <v-col cols="8" align-self="center">
            <v-btn
              block
              rounded
              color="primary"
              @click.stop="logo.dialog = true"
            >
              LOGO HOCHLADEN
            </v-btn>
          </v-col>
        </v-row>
        <v-dialog
          v-model="logo.dialog"
          :max-width="$vuetify.breakpoint.mdAndUp ? '700' : 'none'"
          :overlay-opacity="$vuetify.breakpoint.mdAndUp ? 0.85 : undefined"
          :hide-overlay="$vuetify.breakpoint.mdAndUp ? false : true"
          :transition="
            $vuetify.breakpoint.mdAndUp
              ? 'dialog-transition'
              : 'dialog-bottom-transition'
          "
          :fullscreen="$vuetify.breakpoint.mdAndUp ? false : true"
          :width="$vuetify.breakpoint.mdAndUp ? 'auto' : '100vw'"
          content-class="rounded-xl"
        >
          <v-card class="rounded-xl">
            <v-toolbar color="primary" dark>
              <v-btn icon @click="closeDialog()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Logo hochladen</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text :loading="logo.uploading" @click="upload()">
                  Speichern
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-row justify="center" class="my-4">
                <v-col :cols="logo.imageData ? 12 : 10">
                  <v-image-input
                    v-model="logo.imageData"
                    :image-quality="0.85"
                    clearable
                    accept="image/*"
                    :hideActions="logo.imageData ? false : true"
                    :imageWidth="$vuetify.breakpoint.mdAndUp ? 256 : 192"
                    :imageMinScaling="'contain'"
                    :imageHeight="$vuetify.breakpoint.mdAndUp ? 256 : 192"
                    :full-width="true"
                    image-format="png"
                  />
                </v-col>
                <v-col cols="12" class="text-center" v-if="!logo.imageData">
                  Für eine gute Darstellung wähle bitte ein Bild mit mindestens
                  256 x 256 Pixeln.
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions right>
              <v-btn
                color="primary"
                right
                rounded
                :loading="logo.uploading"
                @click="upload()"
              >
                Speichern
              </v-btn>
              <v-btn color="secondary" right rounded @click="closeDialog()">
                Abbrechen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="12" md="6" align-self="center">
        <card :item="demo" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { doc, updateDoc, deleteDoc } from 'firebase/firestore'
import router from '../../../router'
import Swal from 'sweetalert2'
import card from '../../../components/Sportangebot/card.vue'

const Toast = Swal.mixin({
  toast: true,
  position: 'top-right',
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast'
  },
  showConfirmButton: false,
  timer: 1500,
  timerProgressBar: true
})

export default {
  components: { card },
  name: 'Verein',
  data() {
    return {
      verein: '',
      vdoc: '',
      unsaved_changes: false,
      logo: {
        dialog: false,
        uploading: false,
        imageData: '',
      },
      demo: {
        name: 'Demoangebot',
        author: 'Admin',
        verein: this.verein,
        beschreibung: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
        sportart: {
          name: 'Tennis',
          id: 'Tennis',
          iconset: 'Fontawesome',
          icon: 'fa-duotone fa-tennis-ball',
          created: true,
          active: true
        },
        veranstaltungsort: {
          name: 'Sportanlage',
          adresse: 'Teststr. 1',
          plz: '48268',
          ort: 'Greven',
        },
        altersgruppen: ['10', '11', '12'],
        geschlecht: 'Für Alle',
        teilnehmerzahl: 15,
        termin: { datum: '2022-05-28', beginn: '15:00', ende: '16:30' },
        termine: [{ datum: '', beginn: '', ende: '' }],
        zeitraum: [
          {
            wochentag: '',
            datum: { von: '', bis: '' },
            uhrzeit: { beginn: '', ende: '' },
          },
        ],
        wiederholung: 'Einmaliger Termin',
        material: [
          {
            icon: 'shoe-sneaker',
            iconset: 'mdi',
            name: 'Tennisschuhe'
          }
        ],
        ansprechpartner: [
          {
            vorname: 'Beispiel',
            nachname: 'Trainer',
            telefon: '0123456789',
            telefonpublic: true,
            whatsapp: true,
            email: 'test-trainer@finde-deinen-sport.app',
            emailvalid: true,
            funktion: 'Tennistrainer',
          },
        ],
        fotos: [
          {
            dialog: false,
            imageData: "",
            img: '/img/examples/02.jpg',
            titelbild: true,
            uploading: false
          }
        ],
        id: '12345Test',
        sonstiges: '',
        created_at: '12345',
        created: false,

      },
    }
  },
  mounted() {
    this.setVerein()
  },
  computed: {
    ...mapGetters({
      user: 'user',
      vereine: 'vereine',
      db: 'db',
    }),
  },
  methods: {
    update(){
      this.unsaved_changes = true
      this.demo.verein = this.verein
    },
    save() {
      try {
        updateDoc(this.vdoc, this.verein)
        Toast.fire({
          icon: 'success',
          title: 'Erfolgreich gespeichert!'
        })
        this.unsaved_changes = false
      }
      catch(error){
        Toast.fire({
          icon: 'error',
          title: 'Beim Speichern ist ein Fehler aufgetreten!'
        })
      }
    },
    setVerein() {
      this.vereine.all.forEach((v) => {
        if (v.id == this.user.data.verein.id) {
          this.verein = v
          this.demo.verein = this.verein
        }
      })
      if (this.verein) {
        this.vdoc = doc(this.db, 'Vereine', this.verein.id)
      } else {
        setTimeout(() => {
          this.setVerein()
        }, 50)
      }
    },
    closeDialog() {
      this.logo = {
        dialog: false,
        uploading: false,
        imageData: '',
      }
    },
    upload() {
      if (this.logo.imageData) {
        this.verein.logo = this.logo.imageData
        this.logo.uploading = false
        this.logo.imageData = ''
        this.logo.dialog = false

        this.update()
      }
    },
  },
}
</script>
